import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Bars3Icon } from '@heroicons/react/24/outline'

interface NavbarProps {
  onMenuClick?: () => void
}

export default function Navbar({ onMenuClick }: NavbarProps) {
  const [scrolled, setScrolled] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.6 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'glass py-4' : 'bg-transparent py-6'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold gradient-text">
            AIUMAN
          </Link>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link 
              to="/ai-detector" 
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/ai-detector' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              AI Detector
            </Link>
            <Link 
              to="/ai-humanizer"
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/ai-humanizer' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              AI Humanizer
            </Link>
            <Link 
              to="/ai-writer"
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/ai-writer' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              AI Writer
            </Link>
            <Link 
              to="/voiceover-ai"
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/voiceover-ai' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              Voiceover AI
            </Link>
            <Link 
              to="/imagine-ai"
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/imagine-ai' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              Imagine AI
            </Link>
            <Link 
              to="/conversational-ai"
              className={`hover:text-purple-400 transition-colors ${
                location.pathname === '/conversational-ai' ? 'text-purple-400' : 'text-gray-300'
              }`}
            >
              Conversational AI
            </Link>
            
            <Link 
              to="/pricing"
              className="px-4 py-2 rounded-full text-sm font-medium bg-purple-500 hover:bg-purple-600 transition-all duration-300 transform hover:scale-105 text-white"
            >
              Get Started
            </Link>
          </div>

          <button
            className="md:hidden p-2"
            onClick={onMenuClick}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </motion.nav>
  )
}
