import { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { createPaymentIntent } from '../lib/stripe'

interface PaymentFormProps {
  priceId: string;
  amount: string;
}

export default function PaymentForm({ priceId, amount }: PaymentFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!stripe || !elements) return

    setProcessing(true)
    try {
      const clientSecret = await createPaymentIntent(priceId, amount)
      
      const { error: paymentError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: 'Customer Name',
            email: 'customer@example.com'
          }
        }
      })

      if (paymentError) throw paymentError
      window.location.href = '/success'
    } catch (err:any) {
      setError(err.message)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
      <CardElement 
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#ffffff',
              '::placeholder': { color: '#aab7c4' }
            }
          }
        }}
      />
      {error && <div className="text-red-500">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || processing}
        className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-purple-500 to-violet-500 hover:opacity-90 disabled:opacity-50"
      >
        {processing ? 'Processing...' : `Pay ₹${Math.ceil(parseFloat(amount) * 83)}`}
      </button>
    </form>
  )
}