export interface Product {
  id: string
  priceId: string
  price: string
  name: string
  description: string
  features: string[]
  popular?: boolean
}

export interface StripeConfig {
  products: {
    basic: Product
    pro: Product
    startup: Product
  }
  publishableKey: string
}

export const stripeConfig: StripeConfig = {
  products: {
    basic: {
      id: 'prod_RKdaGWTPTMfKpn',
      priceId: 'price_1QRyJQLvly4Lkaf5dhCTdYlX',
      price: '19',
      name: 'Basic',
      description: 'Perfect for individuals and small teams',
      features: [
        'AI Detection: 100 scans/month',
        'AI Humanizer: 50 texts/month',
        'AI Writer: 25 generations/month',
        'Voiceover: 10 minutes/month',
        'Image Generation: 20 images/month',
        'Chat AI: 100 messages/month',
        'Email Support',
        'Basic Analytics'
      ]
    },
    pro: {
      id: 'prod_RKdakl1TCcR3XO',
      priceId: 'price_1QRyJkLvly4Lkaf5aWsUqttG',
      price: '29',
      name: 'Pro',
      description: 'Ideal for growing businesses',
      popular: true,
      features: [
        'AI Detection: Unlimited scans',
        'AI Humanizer: 200 texts/month',
        'AI Writer: 100 generations/month',
        'Voiceover: 60 minutes/month',
        'Image Generation: 100 images/month',
        'Chat AI: Unlimited messages',
        'Priority Support',
        'Advanced Analytics',
        'API Access'
      ]
    },
    startup: {
      id: 'prod_RKdbTflgAauXo5',
      priceId: 'price_1QRyKDLvly4Lkaf50YODjkYa',
      price: '49',
      name: 'Startup',
      description: 'For large organizations',
      features: [
        'Unlimited Everything',
        'Custom AI Models',
        'Dedicated Account Manager',
        'Custom Integration Support',
        'Advanced Security Features',
        'SLA Guarantee',
        'Team Training',
        'Custom Analytics Dashboard'
      ]
    }
  },
  publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
}

export const plans = Object.values(stripeConfig.products)

export default stripeConfig