import { useState } from 'react'
import { motion } from 'framer-motion'
import { CheckIcon } from '@heroicons/react/24/outline'
import { plans } from '@/config/stripe'
import PaymentForm from './PaymentForm'

export default function Pricing() {
  const [selectedPlan, setSelectedPlan] = useState<{priceId: string; amount: string} | null>(null)

  if (selectedPlan) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">Complete Your Purchase</h2>
        <PaymentForm 
          priceId={selectedPlan.priceId} 
          amount={selectedPlan.amount}
        />
        <button 
          onClick={() => setSelectedPlan(null)}
          className="mt-4 text-gray-400 hover:text-gray-300 text-sm"
        >
          ← Back to plans
        </button>
      </div>
    )
  }

  return (
    <section className="py-24 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-600/10 via-violet-500/5 to-transparent" />
        <div className="absolute top-0 left-0 w-full h-full" style={{
          backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
                           linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px)`,
          backgroundSize: '64px 64px'
        }} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-fuchsia-500">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-400">Choose the perfect plan for your needs</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="relative"
            >
              {plan.popular && (
                <div className="absolute -top-4 left-0 right-0 flex justify-center z-10">
                  <div className="bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white text-sm font-bold px-4 py-1 rounded-full shadow-lg">
                    Most Popular
                  </div>
                </div>
              )}

              <div
                className={`relative h-full backdrop-blur-sm rounded-2xl p-8 transition-all duration-300 ${
                  plan.popular 
                    ? 'bg-purple-900/30 border-2 border-violet-500/50 shadow-xl shadow-purple-500/10' 
                    : 'bg-purple-900/20 border border-purple-500/20 hover:border-purple-500/30'
                }`}
              >
                <div className="text-center mb-8">
                  <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                  <p className="text-gray-400 mb-6">{plan.description}</p>
                  <div className="flex items-baseline justify-center gap-2">
                    <span className="text-4xl font-bold">${plan.price}</span>
                    <span className="text-gray-400">/month</span>
                  </div>
                </div>

                <div className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <div key={feature} className="flex items-start gap-3">
                      <CheckIcon className="h-6 w-6 text-purple-400 flex-shrink-0" />
                      <span className="text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>

                <button
                  onClick={() => setSelectedPlan({ 
                    priceId: plan.priceId, 
                    amount: plan.price
                  })}
                  className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-purple-500 to-violet-500 hover:opacity-90 transition-all duration-300"
                >
                  Get Started
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-400">
            All plans include a 7-day free trial. No credit card required.
          </p>
        </div>
      </div>
    </section>
  )
}